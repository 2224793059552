import React from "react"
import YouTubePlayer from "react-player/lib/players/YouTube"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const VideoPage = ({ data }) => (
  <div>
    <div className="subtitle">{data.allVideos.edges[0].node.title}</div>
    <div className="player-wrapper">
      <YouTubePlayer
        className="react-player"
        controls
        url={`https://www.youtube.com/watch?v=${
          data.allVideos.edges[0].node.id
        }`}
        width="60%"
        height="60%"
      />
    </div>
  </div>
)

export default VideoPage

export const pageQuery = graphql`
  query($id: String!) {
    allVideos(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`
